@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

body {
  font-family:
    'Open Sans'
    BlinkMacSystemFont,
    -apple-system,
    "Segoe UI",
    Oxygen,
    Roboto,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer {
  background: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
}