@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  font-family:
    'Open Sans'
    BlinkMacSystemFont,
    -apple-system,
    "Segoe UI",
    Oxygen,
    Roboto,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer {
  background: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
}
.app-container {
  margin-top: 50px;
}
.status-container {
  margin-bottom: 20px;
}
.grid-container {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 6px;
}
.board-row:after {
  clear: both;
  content: "";
  display: table;
}
.cell {
  border-width: 0px;
  padding-top: 100%;
  background-size: 100% 100%;
}
/* color choices */
.green {
  background-color: #93BD73;
}
.yellow {
  background-color: #FFDD9E;
}
.orange {
  background-color: #E85F5C;
}
.burgundy {
  background-color: #844751;
}
.lilac {
  background-color: #D4CBE5;
}
.cyan {
  background-color: #86DFF5;
}
.blue {
  background-color: #557E93;
}
.mint {
  background-color: #BBE1C3;
}
.hidden {
  background-color: #CDD3CD;
}
.matched {
  background-color: white;
}
